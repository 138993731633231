var tabs = function() {
  var _urlHash = window.location.hash;
  var sTabs = '.js-tabs';
  var oContentContainer = $('.tabbedContent').length > 0 ? $('.tabbedContent') : $('.' + $(sTabs).data('tab-content-container'));
  var aTabbedContent = oContentContainer.find('> *');
  var oTab = $(sTabs + ' li a');
  var activeClass = 'active';
  var sTabHref = null;
  var oActiveTab = null;

  /***/
  function toggleTabbedContent() {
    $(aTabbedContent).hide();
    oActiveTab.fadeIn();

    /**
     * apply active css class to tabs li
     */
    $(sTabs).find('li').removeClass(activeClass);
    $(sTabs + ' [href="'+ sTabHref +'"]').parents('li').addClass(activeClass);
  }

  /**/
  function triggerThis(obj, scroll) {
    sTabHref = obj.attr('href') || _urlHash;
    oActiveTab = $(sTabHref);

    /**
     * Hide/Show tabbed content
     */
    if(scroll)
    {
      if(oActiveTab.length > 0) {
        /**
         * change URL hash value to clicked item href value
         */
        if(history.pushState) {
          history.pushState(null, null, sTabHref);
        }
        else {
          location.hash = sTabHref;
        }
      }
    }

    toggleTabbedContent();
  }

  /**
   * do stuff
   */
  if($(sTabs).length > 0) {
    if(_urlHash.length == 0) {
      triggerThis($(sTabs).find('li:first-child').find('a'));
    }
    else {
      triggerThis($(_urlHash));
    }

    /**/
    oTab.on('click', function(e) {
      e.preventDefault();
      triggerThis($(this));
    });

    /**
     * on hashchange do...
     */
    $(window).on('hashchange', function() {
      _urlHash = location.hash;
      triggerThis($(_urlHash));
    });
  }
}();

/**
 * accordion function
 *
 * @param parentContainer
 * @param accordionItem
 * @param trigger
 * @param slidingPanel
 */
function accordion(parentContainer,accordionItem,trigger,slidingPanel)
{
  $(parentContainer).addClass('accordion');
  $(accordionItem).addClass('accordionItem');
  $(trigger).addClass('triggerAccordion');
  $(slidingPanel).addClass('accordionSlidingPanel');

  $(parentContainer + ' > ' + accordionItem + ' > ' + slidingPanel).hide();
  $(parentContainer + ' > ' + accordionItem + ':first-child').addClass('active').find(slidingPanel).show();

  $(parentContainer + ' > ' + accordionItem + ' > ' + trigger).click(function() {
    $(accordionItem).removeClass('active');
    $(slidingPanel).stop(true,true).slideUp();
    $(this).parent().addClass('active');
    $(this).parent().find(slidingPanel).stop(true,true).slideDown();
  });
}

/**
 * Form input field watermark/helper
 * Could probably be made better, but can be looked at another time
 *
 * @param parentWrapper
 * @param labelParent
 */
function watermark(parentWrapper, labelParent) {
  parentWrapper = parentWrapper || 'body';
  labelParent   = labelParent || '.inputWrapper';

  var formWrapper = $(parentWrapper + ' ' + labelParent);
  var label = formWrapper.find('label');

  formWrapper.find('input[type="text"], input[type="password"], input[type="tel"], input[type="email"], textarea').addClass('formElement');
  formWrapper.find('select').parent().addClass('selectList');
  formWrapper.addClass('watermark');
  var formElement = $('.formElement').attr('autocomplete', 'off');

  $(label).each(function() {
    var labelValue = $(this).text();

    $(this).parent().find('select').append("<option selected='selected' value='ignore' disabled='disabled'>"+ labelValue +"</option>");

    formElement.focus(function()
    {
      if($(this).val().length < 1) {
        $(this).parent().find('label').css({
          'opacity': 0.4
        });

        $(this).keydown(function() {
          $(this).parent().find('label').addClass('positionOff');
        });
      }
    });

    formElement.blur(function() {
     if($(this).val().length < 1) {
       $(this).parent().find('label').css({
        'opacity': 1
       });

       $(this).parent().find('label').removeClass('positionOff');
     }
    });

    if($(this).parent().find(formElement).val() != "") {
      $(this).addClass('positionOff');
    }
  });
}



$.fn.lightbox = function(options) {
  var settings = {
    onClose: function () {}
  };

  if(typeof options != 'undefined') {
    jQuery.extend(settings, options);
  }

  var closeLightbox = function(){
    if (!$(".modal").is(":visible")) {
      $(".lightbox").hide();
      $('.lb-overlay').remove();
    }

    if (typeof settings.onClose == "function") settings.onClose();

    return false;
  };

  return this.each(function() {
    var overlay = $('<div class="lb-overlay"></div>');
    overlay.prependTo('body');
    var $this = $(this);
    var $top = ((($(window).height() - $this.outerHeight()) / 2 + $(window).scrollTop()) < 0) ? 0 : (($(window).height() - $this.outerHeight()) / 2 + $(window).scrollTop());
    var $left = ((($(window).width() - $this.outerWidth()) / 2 + $(window).scrollLeft()) < 0) ? 0 : (($(window).width() - $this.outerWidth()) / 2 + $(window).scrollLeft());


    if ($this.hasClass('lbAlignTop') && $top < $(window).height())
    {
       $top = $top > 0 ? $top - ($top*0.6 ) : 0;
    }
    $this.css({'top': $top, 'left': $left}).show();

    overlay.click(closeLightbox);
    $('.lb-close').click(closeLightbox);
  });
};

$.fn.lightbox_hide = function() {
  return this.each(function() {
    var $this = $(this);
    $('.lb-overlay').remove();
    $this.hide();
  });
};

/**/
function lb_open($lightboxID, $urlhash)
{
  if(window.location.hash){
    var hash = window.location.hash.substring(1);
    if (hash == $urlhash) {
      $($lightboxID).lightbox();
    }
  }
}

/**/
var triggerLightbox = function() {
  var element = '.js-trigger-lightbox';
  var el = $(element);
  var id;

  el.on('click', function(e) {
    id = $(this).data('lightbox-id');
    var lb = $('#lightbox').find('#' + id);

    if(lb.length > 0) {
      lb.lightbox();
    }

    e.preventDefault();
  });
}();

/**
 * Call lightbox by applying class of 'js-lightbox' to trigger
 * Attr 'data-lightbox-id' value will be the ID of the lightbox to open.
 */
$('.js-lightbox').on('click', function() {
  $('#' + $(this).data('lightbox-id')).lightbox();
  return false;
});

/**
 * obfuscate mailto email addresses
 */
var emailObfuscate = function()
{
  var el = $('.js-obfuscate-email');

  $.each(el, function() {
    var user    = $(this).data('user');
    var at      = "@";
    var domain  = $(this).data('domain');
    var tld     = $(this).data('tld');
    var mailto  = 'mailto:' + user + at + domain + '.' + tld;

    $(this).html(function() {
      $(this).attr('href', mailto);
      return user + at + domain + '.' + tld;
    });
  });
}();

/**
 * Trigger javascript popup window (not lightbox)
 */
var showPopup = function() {
  var ele = '.js-trigger-popup';
  var el  = $(ele);
  var url;
  var pWidth;
  var pHeight;

  $(el).on('click', function(e) {
    e.preventDefault();

    url     = $(this).data('url') || $(this).data('popup-url') || $(this).attr('href');
    pWidth  = $(this).data('width') || 700;
    pHeight = $(this).data('height') || 800;

    window.open(
      url,
      "popupWindow",
      "width="+ pWidth +", height="+ pHeight +", scrollbars=yes"
    );
  })
}();

/**
 * header user locale select/dropdown
 */
var doSelectLocale = function() {
  var elParent    = '#user-locale';
  var el          = $(elParent);
  var activeClass = 'active';
  var el_ie       = $('.lt-ie8 ' + elParent);

  el.on('click', function(e) {
    $(this).toggleClass(activeClass);
    e.stopPropagation();
  });

  /**
   * Remove active class by clicking anywhere
   */
  $('html').on('click', function() {
    el.removeClass(activeClass);
  });

  /**
   * bug fix for IE7 - for some reason zipcloud doesn't show unless this is
   * added as an inline style, even though it is in the main.css
   */
  if(el_ie.length) {
    el_ie.css({'position' : 'absolute'});
  }
}();

/**
 * "sticky footer" script
 */
(function() {
  var oFooter = $('#footer');
  var oContent = $('#content');
  oContent = oContent.find('> :last-child').length ? oContent.find('> :last-child') : oContent;
  var nWindowHeight, nFooterHeight, nFooterPosition, nSpaceRemains, oFooterPush;

  if(!oFooter.length) {
    return;
  }

  /**/
  function setVars() {
    nWindowHeight = $(window).height();
    nFooterHeight = oFooter.outerHeight(true);
    nFooterPosition = oFooter.position();
    nSpaceRemains = parseInt((nWindowHeight - nFooterHeight) - nFooterPosition.top);
  }

  /**/
  function pushFooter() {
    setVars();

    if(!oFooterPush) {
      oContent.append('<div id="footerPush"></div>');
      oFooterPush = $('#footerPush');
    }

    oFooterPush.css('padding-bottom', nSpaceRemains + 'px');
  }

  /**/
  $(document).ready(function() {
    pushFooter();
  });
})();

/**
 * custom on 'hide' || 'show' functions
 */
(function ($) {
  $.each(['show', 'hide'], function (i, ev) {
    var el = $.fn[ev];
    $.fn[ev] = function () {
      this.trigger(ev);
      return el.apply(this, arguments);
    };
  });
})(jQuery);
